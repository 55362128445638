<template>
    <div>
     <h1 class="mem-title white--text font-30 app-medium-font" v-if="!isMember">Members Register</h1>
     <h1 class="mem-title white--text font-30 app-medium-font" v-if="isMember">Member in an Agency</h1>
      <v-card class="membr-card" v-if="!isMember">
          <v-card-title>
                Register Member
          </v-card-title>
          <v-card-text>
                <v-form ref="form" v-if="!isMember" @submit.prevent="addMember">
                     <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
                          Select Agency
                     </div>
                     <v-select
                          :items="members"
                          v-model="selectedAgency"
                          item-text="name"
                          item-value="agency_id"
                          label="Select Agency"
                          solo
                          flat
                          outlined
                          class="mt-2 app-regular-font"
                          dense
                     />
                     <v-switch
                          v-model="isToggled"
                          label="Member"
                          class="mt-2 app-regular-font"
                          dense
                     />
                     <v-btn
                          color="primary"
                          type="submit"
                     >
                          Submit
                     </v-btn>
                </v-form>
          </v-card-text>
     </v-card>
            <v-card class="membr-card" v-if="isMember">
          <v-card-title>
                Member in Agency
          </v-card-title>
          <v-card-text>
                <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
                     You are already a member of an agency.
                <v-data-table
                     :headers="headers"
                     :items="members"
                     class="elevation-1"
                >
                     <template v-slot:top>
                          <v-toolbar flat>
                                <v-toolbar-title>Agency Members</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                          </v-toolbar>
                     </template>
                     <template #members.agency_id="{ members }">
                          <span>{{ members.name }}</span>
                     </template>
                </v-data-table>
                </div>
          </v-card-text>
     </v-card>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
     data() {
          return {
                selectedAgency: null ,
                isToggled: false,
                regForm:true,
                errorMessage: null,
                snackbar: false,
                members: [],
                profiledata: null,
                isMember: null,
                headers: [
                     { text: "Agency", value: "agency_id" },
                     { text: "Role", value: "name" }
                ]
          }
     },
      methods: {
     ...mapActions({
        addMemberAction: "members/addMember",
        fetchAllAgency: "members/fetchAllAgency"
     }),
     showErrorMessage(message) {
        this.errorMessage = message;
        this.snackbar = true;
     },
     async addMember() {
        try {
          const params = {
                     agency_id: this.selectedAgency,
                     isMember: this.isToggled
          };
          await this.addMemberAction(params);
          this.isMember = this.profile.isMember; 
        } catch (error) {
          this.showErrorMessage(error.message || "An error occurred while adding the member.");
        }
     },
     async loadMembers() {
        try {
          this.loading = true;
          const response = await this.fetchAllAgency();
          this.members = response;
        } catch (error) {
          this.showErrorMessage(
             error.message || "An error occurred while loading members."
          );
        }
     }
  },
computed: {
     ...mapState("auth", {
          profile: "profile",
     })
},
watch: {
     profile(newProfile) {
         this.profiledata = newProfile;
         this.isMember = newProfile.isMember;
     }
  },
  mounted() {
    console.log(this.isMember,this.profile);
    
          if (this.profile) {
              this.isMember = this.profile.isMember;
              console.log(this.isMember,"this.profile");
          }
          this.loadMembers();
  }
}
</script>
<style scoped>
.membr-card{
     margin-top: 8%;
}
.mem-title{
     margin-top: 18%;
     font-family: "Poppins-Medium" !important;
     margin-left: 17px;

}
</style>